@use 'sass:math';
@import '../../../node_modules/@gisatcz/ptr-core/lib/styles/variables';

$disabledOpacity: .2;
$disabledColor: var(--base30);

/* Legend */
.insar-Legend {
	margin: m(math.div(1,3)) 0;
}

// Header
.insar-LegendHeader {
	margin-bottom: 0.15rem;
}

.insar-LegendHeader > .title {
	color: var(--base90);
	font-size: $b1;
}

.insar-LegendHeader > .subtitle {
	color: var(--base70);
	font-size: $b1;
}

/* Values */
.insar-LegendValuesItem {
	display: flex;
	align-items: center;
	font-size: $b1;
	cursor: pointer;
}

.insar-LegendValuesItem > div {
	width: 1.25rem;
	height: 0.85rem;
	margin-right: 0.5rem;
}

.insar-LegendValuesItem.is-disabled {
	color: $disabledColor;
}

.insar-LegendValuesItem.is-disabled > div {
	opacity: $disabledOpacity;
}

/* Intervals line */
.insar-IntervalsLine {
	display: flex;
}

.insar-IntervalsLine-railWrapper {
	width: 100%;
	margin-right: 1rem;
}

.insar-IntervalsLine-rail {
	display: flex;
}

.insar-IntervalsLine-railCell {
	flex: 1;
	height: 0.75rem;
	cursor: pointer;
}

.insar-IntervalsLine-railCell.is-disabled {
	opacity: $disabledOpacity;
}

.insar-IntervalsLine-rail.longer-tails > .insar-IntervalsLine-railCell:first-child,
.insar-IntervalsLine-rail.longer-tails > .insar-IntervalsLine-railCell:last-child {
	min-width: 20%;
}

.insar-IntervalsLine-rail.longer-tails-first > .insar-IntervalsLine-railCell:last-child {
	min-width: auto;
}

.insar-IntervalsLine-rail.longer-tails-first > .insar-IntervalsLine-railCell:first-child {
	min-width: 30%;
}

.insar-IntervalsLine-rail.longer-tails-last > .insar-IntervalsLine-railCell:first-child {
	min-width: auto;
}

.insar-IntervalsLine-rail.longer-tails-last > .insar-IntervalsLine-railCell:last-child {
	min-width: 30%;
}

.insar-IntervalsLine-labels {
	display: flex;
	font-size: $b1;
	color: var(--base90);
}

.insar-IntervalsLine-labels > span:first-child {
	flex: 1;
}

.insar-IntervalsLine-noDataCell {
	display: flex;
	flex-direction: column;
	align-items: center;
	color: var(--base90);
	font-size: $b1;
	cursor: pointer;
}

.insar-IntervalsLine-noDataCell.is-disabled {
	color: $disabledColor;
}

.insar-IntervalsLine-noDataCell > div {
	height: 0.75rem;
	width: 3rem;
}

.insar-IntervalsLine-noDataCell.is-disabled > div {
	opacity: $disabledOpacity;
}
