@use 'sass:math';
@import '~@gisatcz/ptr-core/lib/styles/_variables';

$lightAccent0: hsl(40,100%,99.41%);
$lightAccent0rgb: 255, 254, 252;
$lightAccent05: hsl(42.5,85.71%,94.51%);
$lightAccent10: hsl(44,84.91%,89.61%);
$lightAccent15: hsl(44.31,82.28%,84.51%);
$lightAccent20: hsl(43.95,82.69%,79.61%);
$lightAccent25: hsl(44.3,82.95%,74.71%);
$lightAccent30: hsl(44.06,83.12%,69.8%);
$lightAccent35: hsl(43.89,83.24%,64.9%);
$lightAccent40: hsl(44.12,83.33%,60%);
$lightAccent45: hsl(44.21,82.61%,54.9%);
$lightAccent50: hsl(44.08,82.75%,50%);
$lightAccent50rgb: 233, 177, 22;
$lightAccent55: hsl(44.21,82.61%,45.1%);
$lightAccent60: hsl(44.02,82.44%,40.2%);
$lightAccent65: hsl(44.3,83.24%,35.1%);
$lightAccent70: hsl(44.19,83.23%,30.39%);
$lightAccent75: hsl(43.89,83.08%,25.49%);
$lightAccent80: hsl(44.14,82.86%,20.59%);
$lightAccent85: hsl(43.64,82.5%,15.69%);
$lightAccent90: hsl(44.35,85.19%,10.59%);
$lightAccent95: hsl(43.2,86.21%,5.69%);
$lightAccent100: hsl(45,100%,0.78%);
$lightAccent100rgb: 4, 3, 0;

$darkAccent0: hsl(45,100%,0.78%);
$darkAccent0rgb: 4, 3, 0;
$darkAccent05: hsl(43.2,86.21%,5.69%);
$darkAccent10: hsl(44.35,85.19%,10.59%);
$darkAccent15: hsl(43.64,82.5%,15.69%);
$darkAccent20: hsl(44.14,82.86%,20.59%);
$darkAccent25: hsl(43.89,83.08%,25.49%);
$darkAccent30: hsl(44.19,83.23%,30.39%);
$darkAccent35: hsl(44.3,83.24%,35.1%);
$darkAccent40: hsl(44.02,82.44%,40.2%);
$darkAccent45: hsl(44.21,82.61%,45.1%);
$darkAccent50: hsl(44.08,82.75%,50%);
$darkAccent50rgb: 233, 177, 22;
$darkAccent55: hsl(44.21,82.61%,54.9%);
$darkAccent60: hsl(44.12,83.33%,60%);
$darkAccent65: hsl(43.89,83.24%,64.9%);
$darkAccent70: hsl(44.06,83.12%,69.8%);
$darkAccent75: hsl(44.3,82.95%,74.71%);
$darkAccent80: hsl(43.95,82.69%,79.61%);
$darkAccent85: hsl(44.31,82.28%,84.51%);
$darkAccent90: hsl(44,84.91%,89.61%);
$darkAccent95: hsl(42.5,85.71%,94.51%);
$darkAccent100: hsl(40,100%,99.41%);
$darkAccent100rgb: 255, 254, 252;

$minimisedHeaderHeight: m(3 + math.div(1,3));
$controlPanelWidth: 35rem;
$controlPanelExpandedWidth: 65rem;
$controlPanelWidth_smallScreens: calc(100% - 1.5rem);
$controlPanelHorizontalPadding: m(1);
$controlPanelHorizontalPadding_smallScreens: m(math.div(1,2));

$controlPanelBackground: rgba(var(--base0rgb), .85);
$controlPanelSectionHeaderBackground: rgba(var(--accent50rgb), .9);

$basicAnimationStyle: 0.5s ease-in-out;