@use 'sass:math';
@import '../../styles/variables';

.insar-Warning {
  position: absolute;
  top: m(math.div(1,3));
  left: m(math.div(1,3));
  z-index: 1000;
  display: flex;
  align-items: center;
  background: rgba(var(--accent50rgb), .85);
  padding: .25rem .5rem;
  grid-gap: .35rem;
  border-radius: .25rem;
}

.insar-Warning-icon {
  border-left: .55rem solid transparent;
  border-right: .55rem solid transparent;
  border-bottom: .9rem solid var(--base100);
  position: relative;
}

.insar-Warning-icon:after {
  content: "!";
  font-weight: 900;
  position: absolute;
  top: -.1rem;
  left: -.15rem;
  color: var(--accent50);
  font-size: .925rem;
  font-family: "fantasy";
}

.insar-Warning-text {
  font-size: $b1;
  color: var(--base100);
}