@import '../../../styles/variables';

.insar-ControlGroupContent .ptr-button-switch.dividedAfterSecondOption .ptr-button:nth-child(3) {
  margin-left: 5px;
  border-left: 1px solid var(--base60);
}

.insar-ControlGroupLabel.alt {
  display: none;
}

@media only screen and (max-width: 800px) {
  .insar-ControlGroupContent .ptr-button-switch.dividedAfterSecondOption .ptr-button:nth-child(3) {
    margin-left: 0;
  }
  .insar-ControlGroupLabel.primary {
    display: none;
  }
  .insar-ControlGroupLabel.alt {
    display: inline;
  }
}