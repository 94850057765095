@import '../../../styles/variables';

.insar-ControlGroupContent .ptr-button-switch.extended .ptr-button {
  height: 3.2rem;
}

.insar-ControlGroupContent .ptr-button-switch.extended .ptr-button-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  padding: calc(0.35rem - 1px) 0 calc(0.35rem - 1px);
}

.insar-ControlGroupContent .ptr-button-switch.extended .ptr-button-content > span {
  font-size: $b1;
}