@use 'sass:math';
@import '../../styles/variables';

/* ControlPanelBlock */
.insar-ControlPanelBlock {
  background: $controlPanelBackground;
  padding: m(math.div(2,3)) m(1) m(1);
  color: var(--base90);
  border-bottom: 1px solid var(--base20);
}

/* ControlGroup */
.insar-ControlGroup {
  margin-bottom: m(math.div(2,3));
}

.insar-ControlPanelBlock .insar-ControlGroup:last-child {
  margin-bottom: 0;
}

/* ControlGroupLabel */
.insar-ControlGroupLabel {
  font-size: $b1;
  color: var(--accent70);
}

/* ControlGroupContent */
.insar-ControlGroupContent {
  margin-top: .1rem;
}

@media only screen and (max-width: 800px) {
  .insar-ControlPanelBlock {
    padding: m(math.div(2,3)) $controlPanelHorizontalPadding_smallScreens m(1);
  }
}

