@import '../../styles/variables';

.insar-LoadingOverlay {
	display: none;
	position: absolute;
	right: 0;
	top: 0;
	left: 0;
	bottom: 0;
	z-index: 100000;
	background: rgba(var(--accent0rgb), .8);
	align-items: center;
	justify-content: center;
	transition: opacity .3s ease-in-out;
}

.insar-LoadingOverlay.is-opening {
	display: flex;
	opacity: 0;
}

.insar-LoadingOverlay.is-open {
	display: flex;
	opacity: 1;
	transition: opacity .3s ease-in-out;
}

.insar-LoadingOverlay.is-closing {
	display: flex;
	opacity: 0;
	transition: opacity .3s ease-in-out;
}

.insar-LoadingOverlayLoader {
	color: var(--base90);
	font-size: $a3;
}

