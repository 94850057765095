@use 'sass:math';
@import '../../styles/variables';

.insar-ControlPanel {
	border-left: 1px solid var(--base20);
	position: absolute;
	height: 100%;
	top: 0;
	right: 0;
	left: auto;
	width: $controlPanelWidth;
	box-shadow: 0 0 10px 5px rgba(0, 0, 0, 0.2);
	z-index: 150;
	transition: width $basicAnimationStyle;
}

.insar-ControlPanel.is-chartExpanded {
	width: $controlPanelExpandedWidth;
}

.insar-ControlPanel.is-minimised,
.insar-ControlPanel.is-chartExpanded.is-minimised {
	width: 0;
}

.insar-ControlPanel-top-padding {
	width: 100%;
	height: $minimisedHeaderHeight;
	background: rgba(var(--base0rgb), 0.5);
}

.insar-ControlPanel-content {
	height: calc(100% - #{$minimisedHeaderHeight});
	overflow: hidden;
	display: flex;
	flex-direction: column;
}

/* Buttons */
.insar-ControlPanel-header {
	height: 3rem;
	display: flex;
	padding: m(math.div(1,3)) $controlPanelHorizontalPadding;
	font-size: $b2;
	font-weight: bold;
}

/* Sections */
.insar-ControlPanel-section {
	height: 100%;
	display: flex;
	flex-direction: column;
	width: $controlPanelWidth;
	transition: width $basicAnimationStyle, flex-grow 0.5s ease-in-out;
}

.insar-ControlPanel.is-chartExpanded .insar-ControlPanel-section {
	width: $controlPanelExpandedWidth;
}

.insar-ControlPanel-section-header {
	padding: m(math.div(1,3)) $controlPanelHorizontalPadding;
	height: 2.5rem;
	background: $controlPanelSectionHeaderBackground;
	letter-spacing: 0.02rem;
	color: var(--accent10);
}

.insar-ControlPanel-section-content {
	flex-grow: 1;
	overflow-y: auto;
	display: flex;
	flex-direction: column;
}

.insar-ControlPanel-data-wrapper {
	flex-grow: 1;
	padding: m(1) 0;
	background: $controlPanelBackground;
}

.insar-ControlPanel-data-wrapper p.centered {
	text-align: center;
}

.insar-ControlPanel-copyright {
	padding: m(math.div(1,3)) $controlPanelHorizontalPadding;
	font-size: $a0;
	color: var(--base60);
	background: $controlPanelBackground;
	letter-spacing: -.015rem;
}

@media only screen and (max-width: 1100px) {
	.insar-ControlPanel.is-chartExpanded {
		width: $controlPanelWidth_smallScreens;
	}
	.insar-ControlPanel.is-chartExpanded .insar-ControlPanel-section {
		width: 100%;
	}
}

@media only screen and (max-width: 800px) {
	.insar-ControlPanel {
		width: $controlPanelWidth_smallScreens;
	}
	.insar-ControlPanel.is-minimised {
		width: 0;
	}
	.insar-ControlPanel-section {
		width: 100%;
	}
	.insar-ControlPanel-section-header {
		padding: m(math.div(1,3)) $controlPanelHorizontalPadding_smallScreens;
		letter-spacing: 0;
	}
	.insar-ControlPanel-section-header > span {
		display: none;
	}
}
