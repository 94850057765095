@import '~@gisatcz/ptr-core/lib/styles/_variables';
@import '~@gisatcz/ptr-maps';
@import '~@gisatcz/ptr-charts';
@import '~@gisatcz/ptr-components';
@import '~@gisatcz/ptr-atoms';
@import '~@gisatcz/ptr-core';
@import './variables';

.ptr-light {
	--accent0: #{$lightAccent0};
	--accent0rgb: #{$lightAccent0rgb};
	--accent05: #{$lightAccent05};
	--accent10: #{$lightAccent10};
	--accent15: #{$lightAccent15};
	--accent20: #{$lightAccent20};
	--accent25: #{$lightAccent25};
	--accent30: #{$lightAccent30};
	--accent35: #{$lightAccent35};
	--accent40: #{$lightAccent40};
	--accent45: #{$lightAccent45};
	--accent50: #{$lightAccent50};
	--accent50rgb: #{$lightAccent50rgb};
	--accent55: #{$lightAccent55};
	--accent60: #{$lightAccent60};
	--accent65: #{$lightAccent65};
	--accent70: #{$lightAccent70};
	--accent75: #{$lightAccent75};
	--accent80: #{$lightAccent80};
	--accent85: #{$lightAccent85};
	--accent90: #{$lightAccent90};
	--accent95: #{$lightAccent95};
	--accent100: #{$lightAccent100};
	--accent100rgb: #{$lightAccent100rgb};
}

.ptr-dark {

	--accent0: #{$darkAccent0};
	--accent0rgb: #{$darkAccent0rgb};
	--accent05: #{$darkAccent05};
	--accent10: #{$darkAccent10};
	--accent15: #{$darkAccent15};
	--accent20: #{$darkAccent20};
	--accent25: #{$darkAccent25};
	--accent30: #{$darkAccent30};
	--accent35: #{$darkAccent35};
	--accent40: #{$darkAccent40};
	--accent45: #{$darkAccent45};
	--accent50: #{$darkAccent50};
	--accent50rgb: #{$darkAccent50rgb};
	--accent55: #{$darkAccent55};
	--accent60: #{$darkAccent60};
	--accent65: #{$darkAccent65};
	--accent70: #{$darkAccent70};
	--accent75: #{$darkAccent75};
	--accent80: #{$darkAccent80};
	--accent85: #{$darkAccent85};
	--accent90: #{$darkAccent90};
	--accent95: #{$darkAccent95};
	--accent100: #{$darkAccent100};
	--accent100rgb: #{$darkAccent100rgb};
}

.emsn091Manila {
	overflow: hidden;
}

.insar-App {
	width: 100%;
	height: 100%;
}

@media only screen and (max-width: 800px) {
	.insar-App .ptr-button-group .ptr-button,
	.insar-App .ptr-buttons .ptr-button-group .ptr-button{
		margin: 0 .35rem .35rem 0;
		border-radius: .15rem;
	}
	.insar-App .ptr-button-group .ptr-button:not(:first-child) {
		border-left: 1px solid var(--base60);
	}
	.insar-App .ptr-button-group .ptr-button.disabled:not(:first-child) {
		border-left: 1px solid var(--base20);
	}
}
