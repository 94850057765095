@import '../../styles/variables';

.insar-MapTooltip-content {
	background: rgba(var(--base0rgb), .85);
	color: var(--base100);
	padding: .5rem .75rem;
	border-radius: .15rem;
	box-shadow: 0 0 4px 2px rgba(var(--base0rgb), .25);
	overflow-y: hidden;
	width: 22rem;
}

.insar-MapTooltip-header {
	font-size: $b1;
}

.insar-MapTooltip-header em {
	font-size: $a1;
	font-weight: bold;
}

.insar-MapTooltip-body {
	margin-top: .35rem;
}

.insar-MapTooltip-attribute {
	display: flex;
	font-size: $b1;
	padding-bottom: .25rem;
	line-height: 1.25;
}
.insar-MapTooltip-attribute-name {
	flex-grow: 1;
}
.insar-MapTooltip-attribute-value {
	margin-left: 2rem;
	font-weight: bold;
}
