@use 'sass:math';
@import '../../../node_modules/@gisatcz/ptr-core/lib/styles/variables';

.insar-InterferometryChart {
	padding: 0 m(1);
	margin-bottom: m(math.div(4,3));
}

.insar-InterferometryChart-header {
	display: flex;
	align-items: center;
	margin-bottom: 0.15rem;
}

.insar-InterferometryChart-controls {
	margin-bottom: -.2rem;
	margin-right: -.5rem;
}

.insar-InterferometryChart-title {
	flex: 1;
	font-weight: bold;
	color: var(--base100);
}

.insar-InterferometryChart-title > span {
	font-size: $b1;
}

.insar-InterferometryChart-settings {
	height: 0;
	overflow: hidden;
	transition: height 0.3s ease-in-out;
	background: var(--base20);
}

.insar-InterferometryChart-settings-item {
	margin: .25rem .75rem;
}

.insar-InterferometryChart-settings-item > span {
	font-size: $b1;
	color: var(--base80);
}

@media only screen and (max-width: 800px) {
	.insar-InterferometryChart {
		display: none;
	}
}
