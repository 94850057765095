@use 'sass:math';
@import '../../styles/variables';

.insar-MapWrapper {
	background: $controlPanelBackground;
	width: calc(100% - #{$controlPanelWidth});
	position: absolute;
	bottom: 0;
	left: 0;
	height: 0;
	z-index: 9999;
	overflow: hidden;
	transition: height 0.5s ease-in-out, max-height 0.5s ease-in-out, width $basicAnimationStyle;
	max-height: 0;
	border-top: 1px solid var(--base20);
}

.insar-MapWrapper.is-chartExpanded {
	width: calc(100% - #{$controlPanelExpandedWidth});
}

.insar-MapWrapper.full-width {
	width: 100%;
}

.insar-MapWrapper.open {
	//height: 50%;
	height: 40rem;
	max-height: 60%;
}

.insar-MapWrapper-header {
	display: flex;
	align-items: center;
	color: var(--accent55);
	height: 3.5rem;
	padding: 0 m(math.div(1,3)) 0 m(1);
	cursor: pointer;
}

.insar-MapWrapper-header > div:first-child {
	flex: 1;
	font-size: $b2;
	display: flex;
	align-items: flex-end;
}

.insar-MapWrapper-header .ptr-icon {
	width: 1.5rem;
	margin-right: 0.35rem;
	fill: var(--accent55);
}

.insar-MapWrapper-content {
	padding: m(math.div(1,3)) m(1) m(1);
	height: calc(100% - 3.5rem);
	overflow-y: auto;
}

// map attribution
.insar-MapAttribution {
	position: absolute;
	bottom: 0;
	left: 0;
	z-index: 2;
	background: rgba(var(--base0rgb), 0.7);
	font-size: $a0;
	padding: 0.15rem 0.5rem;
	color: var(--base80);
	max-width: calc(100% - 3rem);
}

.insar-MainMap .ptr-map-controls-wrapper {
	z-index: 3;
	position: unset;
}

.insar-MainMap .ptr-map-controls {
	right: calc(0.5rem + #{$controlPanelWidth});
	left: auto;
	bottom: m(math.div(1,3));
	transition: right $basicAnimationStyle;
}

.insar-MainMap.is-chartExpanded .ptr-map-controls {
	right: calc(0.5rem + #{$controlPanelExpandedWidth});
}

.insar-MainMap.full-width .ptr-map-controls {
	right: 0.5rem;
}

.insar-MainMap .insar-LayersButton {
	position: absolute;
	bottom: m(3 + math.div(1,3));
	z-index: 3;
}

// direction icons
.insar-MapDirectionIcons {
	position: absolute;
	left: m(math.div(1,3));
	bottom: m(1);
	z-index: 3;
}

// Layers
// ControlGroup TODO make component
.insar-LayersControlGroup-header {
	color: var(--base90);
	font-weight: bold;
	margin-bottom: .25rem;
}

.insar-LayersControlGroup {
	margin-bottom: m(1);
}

// legend
.insar-WmsLayerLegend {
	display: none;
	color: var(--base80);
}

.insar-WmsLayerLegend.is-open {
	display: block;
	margin-bottom: 0.5rem;
}

.insar-WmsLayerLegend > div {
	margin-top: 0.25rem;
}

// legend bar
.insar-WmsLayerLegend-bar {
	width: 100%;
	height: 0.5rem;
	border: 1px solid var(--base50);
	max-width: 40rem;
}

#occurrence-legend .insar-WmsLayerLegend-bar {
	background: linear-gradient(90deg, #fdfbfd, #c387c3, #0202ff);
}

#change-legend .insar-WmsLayerLegend-bar {
	background: linear-gradient(90deg, #fc0000, #8e0000, #000000, #006c00, #00fd00);
}

#seasonality-legend .insar-WmsLayerLegend-bar {
	background: linear-gradient(90deg, #f5fbfd, #8386ee, #0000dc);
}

#recurrence-legend .insar-WmsLayerLegend-bar {
	background: linear-gradient(90deg, #fa8532, #cdab86, #9ed5e1);
}

.insar-WmsLayerLegend-labels {
	display: flex;
	width: 100%;
	max-width: 40rem;
	font-size: $b1;
}

.insar-WmsLayerLegend-labels > span:first-child,
.insar-WmsLayerLegend-labels > span:nth-child(2):not(:last-child) {
	flex: 1;
}

.insar-WmsLayerLegend-list {
	display: flex;
	flex-wrap: wrap;
}

.insar-WmsLayerLegend-item {
	margin-right: 1rem;
	font-size: $b1;
	display: flex;
	align-items: center;
}

.insar-WmsLayerLegend-item > div {
	margin-right: 0.5rem;
	width: 1.25rem;
	height: 0.85rem;
	border: 1px solid var(--base50);
}

@media only screen and (max-width: 800px) {
	.insar-MainMap .ptr-map-controls {
		right: 0.5rem;
	}
}
