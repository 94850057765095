@import '../../styles/variables';

.insar-Checkbox {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.insar-Checkbox.is-disabled {
  pointer-events: none;
  color: var(--base35);
}

.insar-Checkbox > span {
  margin-left: .25rem;
  color: var(--base90);
}