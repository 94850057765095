@use 'sass:math';
@import '../../styles/variables';

$horizontalPadding: m(2);

.insar-IntroOverlay {
	position: absolute;
	right: 0;
	z-index: 99999;
	background: rgba(var(--accent0rgb), .8);
	width: 100%;
	height: 100%;
	overflow: hidden;
	transition: height $basicAnimationStyle, width $basicAnimationStyle, background $basicAnimationStyle;
	display: flex;
	flex-direction: column;
}

// Header
.insar-IntroOverlay-header {
	position: relative;
	padding: m(2) $horizontalPadding m(math.div(2,3)) $horizontalPadding;
	color: var(--base100);
	display: flex;
	flex-direction: column;
	letter-spacing: 0.05rem;
	cursor: pointer;
	transition: padding $basicAnimationStyle, font-size $basicAnimationStyle, width $basicAnimationStyle;
	width: 100%;
	min-height: $minimisedHeaderHeight;
}
.insar-IntroOverlay-header > span {
	display: block;
	color: var(--accent70);
	transition: padding-top $basicAnimationStyle, font-size $basicAnimationStyle;
}
.insar-IntroOverlay-header h1 {
	margin: 0;
	padding-top: .25rem;
	transition: font-size $basicAnimationStyle;
}

.ptr-button.insar-IntroOverlay-control {
	position: absolute;
	top: m(math.div(1,3));
	right: m(math.div(1,3));
	left: auto;
	transition: top $basicAnimationStyle, right $basicAnimationStyle;
	z-index: 1;
	cursor: pointer;
	width: 2rem;
	height: 2rem;
}

.ptr-button.insar-IntroOverlay-control > .ptr-icon {
	transform: rotate(135deg);
	fill: var(--accent70);
	margin: calc(0.25rem - 1px);
	width: 1.5rem;
	height: 1.5rem;
	transition: transform $basicAnimationStyle, width $basicAnimationStyle, height $basicAnimationStyle, margin $basicAnimationStyle;
}

// Content
.insar-IntroOverlay-content {
	padding: m(1) 0 m(1) $horizontalPadding;
	transition: padding $basicAnimationStyle;
	color: var(--accent90);
	position: relative;
	overflow-y: auto;
}

.insar-IntroOverlay-content h3 {
	margin-top: 0;
}

.insar-IntroOverlay-content > div {
	height: 100%;
	overflow-y: auto;
}

.insar-IntroOverlay-content h3,
.insar-IntroOverlay-content p,
.insar-IntroOverlay-content ul,
.insar-IntroOverlay-content ol {
	color: var(--base100);
	padding-right: $horizontalPadding;
}

.insar-IntroOverlay-content ul,
.insar-IntroOverlay-content ol {
	margin-left: m(math.div(4,3));
	margin-bottom: m(1);
}

.insar-IntroOverlay-content ul li,
.insar-IntroOverlay-content ol li {
	color: var(--base100);
}

// CTA
.insar-IntroOverlay-cta {
	padding: m(math.div(2,3)) $horizontalPadding;
	flex: 1;
	display: flex;
}

.insar-IntroOverlay-cta .ptr-button {
	margin: 0 m(math.div(2,3)) m(math.div(2,3)) 0
}

.insar-IntroOverlay-cta .ptr-button .ptr-button-content {
	display: flex;
	flex-direction: column;
	align-items: center;
	padding: .4rem .5rem .5rem;
	max-width: 20rem;
}

.insar-IntroOverlay-cta .ptr-button .ptr-button-content > div {
	font-size: $b1;
	text-align: center;
}

.insar-IntroOverlay-cta .ptr-button .ptr-button-content > span {
	font-size: $b2;
}

.insar-IntroOverlay-cta .ptr-icon {
	transform: rotate(180deg);
}

// Footer
.insar-IntroOverlay-footer {
	padding: m(math.div(2,3))  $horizontalPadding;
	color: var(--base100);
	transition: margin $basicAnimationStyle;
	display: flex;
	align-items: center;
}

.insar-IntroOverlay-logos {
	display: flex;
	align-items: center;
}

.insar-IntroOverlay-logos a {
	max-width: 7rem;
	margin: 0 m(math.div(2,3));
}

.insar-IntroOverlay-copyrights {
	font-size: $b1;
	flex: 1;
}

.insar-IntroOverlay-copyrights a {
	color: var(--base100);
}

/* minimised */
.insar-IntroOverlay.is-minimised {
	top: 0;
	right: 0;
	height: $minimisedHeaderHeight;
	width: $controlPanelWidth;
	background: $controlPanelBackground;
	overflow: hidden;
}

.insar-IntroOverlay.is-minimised.is-chartExpanded {
	width: $controlPanelExpandedWidth;
}

.insar-IntroOverlay.is-minimised:hover {
	background: var(--accent0);
}

.insar-IntroOverlay.is-minimised .ptr-button.insar-IntroOverlay-control {
	top: .2rem;
	right: .2rem;
}

.insar-IntroOverlay.is-minimised .insar-IntroOverlay-control .ptr-icon {
	transform: rotate(315deg);
	margin: calc(0.375rem - 1px);
	width: 1.25rem;
	height: 1.25rem;
	opacity: 1;
	transition: $basicAnimationStyle;
}

.insar-IntroOverlay.is-minimised .insar-IntroOverlay-header {
	left: 0;
	padding: m(math.div(2,3)) m(1);
	width: $controlPanelWidth;
}

.insar-IntroOverlay.is-minimised.is-chartExpanded .insar-IntroOverlay-header {
	width: $controlPanelExpandedWidth;
}

.insar-IntroOverlay.is-minimised .insar-IntroOverlay-header > span {
	font-size: $b1;
}

.insar-IntroOverlay.is-minimised .insar-IntroOverlay-header > h1 {
	font-size: $a2;
}

.insar-IntroOverlay.is-minimised .insar-IntroOverlay-footer,
.insar-IntroOverlay.is-minimised .insar-IntroOverlay-cta {
	visibility: hidden;
}

.insar-IntroOverlay.is-minimised .insar-IntroOverlay-content,
.insar-IntroOverlay.is-minimised .insar-IntroOverlay-footer {
	margin-left: 0;
}

/* hidden */
.insar-IntroOverlay.is-hidden,
.insar-IntroOverlay.is-chartExpanded.is-hidden {
	width: 0;
}

.insar-IntroOverlay.is-hidden .insar-IntroOverlay-control .ptr-icon {
	opacity: 0;
}

@media only screen and (max-width: 1100px) {
	.insar-IntroOverlay.is-minimised.is-chartExpanded {
		width: $controlPanelWidth_smallScreens;
	}
	.insar-IntroOverlay.is-hidden.is-chartExpanded {
		width: 0;
	}
	.insar-IntroOverlay.is-minimised.is-chartExpanded .insar-IntroOverlay-header {
		width: $controlPanelWidth_smallScreens;
		padding: m(math.div(2,3)) $controlPanelHorizontalPadding_smallScreens;
	}
}

@media only screen and (max-width: 800px) {
	.insar-IntroOverlay-header {
		padding: m(1) m(math.div(5,3)) m(math.div(1,3)) m(math.div(2,3));
	}
	.insar-IntroOverlay-content {
		padding: m(math.div(1,3)) 0 m(math.div(1,3)) m(math.div(2,3));
	}
	.insar-IntroOverlay-content h3 {
		font-size: $a1;
	}
	.insar-IntroOverlay-cta {
		padding: m(math.div(4,3)) m(math.div(2,3));
	}
	.insar-IntroOverlay-footer {
		padding: 0 m(math.div(2,3)) m(1);
		flex-direction: column;
		align-items: flex-start;
	}
	.insar-IntroOverlay-logos {
		flex-wrap: wrap;
	}
	.insar-IntroOverlay-logos a {
		max-width: 5rem;
		margin: m(math.div(2,3)) m(math.div(2,3)) 0 0;
	}

	// minimised
	.insar-IntroOverlay.is-minimised {
		width: $controlPanelWidth_smallScreens;
	}
	.insar-IntroOverlay.is-hidden {
		width: 0;
	}
	.insar-IntroOverlay.is-minimised .insar-IntroOverlay-header {
		width: $controlPanelWidth_smallScreens;
		padding: m(math.div(2,3)) $controlPanelHorizontalPadding_smallScreens;
	}
	.insar-IntroOverlay.is-minimised .insar-IntroOverlay-header span {
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
	}
}

