@use 'sass:math';
@import '../../../styles/variables';

$controlHeight: 2.5rem;

.insar-ControlButton {
	position: absolute;
	top: calc(50% - #{math.div($controlHeight,2)});
	right: $controlPanelWidth;
	z-index: 100;
	transition: right $basicAnimationStyle;
	box-shadow: 0 0 10px 5px rgba(0, 0, 0, 0.2);
	background: $controlPanelBackground;
	border: 1px solid var(--base20);
	border-right: 0;
}

.insar-ControlButton.is-chartExpanded {
	right: $controlPanelExpandedWidth;
}

.insar-ControlButton:focus,
.insar-ControlButton:focus:hover {
	border-right: 0;
}

.insar-ControlButton:focus::after {
	border-top-right-radius: 0;
	border-bottom-right-radius: 0;
	border-right: 0;
}

.insar-ControlButton .ptr-icon {
	transition: transform $basicAnimationStyle;
}

.insar-ControlButton.is-minimised,
.insar-ControlButton.is-chartExpanded.is-minimised {
	right: 0;
}

.insar-ControlButton.is-minimised .ptr-icon {
	transform: rotate(180deg);
}

@media only screen and (max-width: 1100px) {
	.insar-ControlButton.is-chartExpanded {
		right: $controlPanelWidth_smallScreens;
	}
}

@media only screen and (max-width: 800px) {
	.insar-ControlButton {
		right: $controlPanelWidth_smallScreens;
	}
}
