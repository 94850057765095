@import '../../styles/_variables';

.insar-MainMapPanel {
	width: 100%;
	height: 100%;
	position: absolute;
	top: 0;
	left: 0;
	z-index: 1;
}

.insar-MainMap {
	width: 100%;
	height: 100%;
}
