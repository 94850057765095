@use 'sass:math';
@import '../../styles/variables';

.insar-AttributesPanel {

}
.insar-AttributesPanel-set > h5 {
  padding: 0 $controlPanelHorizontalPadding;
}
.insar-AttributesPanel-set > h6 {
  padding: 0 $controlPanelHorizontalPadding;
  margin-top: m(math.div(1,3));
  font-weight: bold;
  font-size: $b1;
}
.insar-AttributesPanel-attribute {
  display: flex;
  font-size: $b1;
  line-height: 1.25;
  padding: 0.2rem $controlPanelHorizontalPadding;
  color: var(--base80);
}
.insar-AttributesPanel-attribute:hover {
  background: var(--base20);
}
.insar-AttributesPanel-attribute > div {
  display: block;
  flex: 1 1 m(4);
}
.insar-AttributesPanel-attribute > span:first-of-type {
  display: block;
  flex: 0 1 m(3);
  text-align: right;
  font-weight: bold;
}
.insar-AttributesPanel-attribute > span.long {
  flex: 0 1 m(10);
}
.insar-AttributesPanel-attribute > span:last-child {
  display: block;
  flex: 0 1 m(math.div(5,3));
  color: var(--base80);
  margin-left: m(math.div(1,4));
}

@media only screen and (max-width: 800px) {
  .insar-AttributesPanel-set > h5 {
    padding: 0 $controlPanelHorizontalPadding_smallScreens;
  }
  .insar-AttributesPanel-attribute {
    padding: 0.2rem m(math.div(1,6)) 0.2rem $controlPanelHorizontalPadding_smallScreens;
  }
}
